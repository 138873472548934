import axios from "axios";

export default (endpoint, method, data, isMultipart) =>
  {
    return axios({
    url: `https://dev-api.ponderiee.com/api/v1/${endpoint}`,
    // url: `https://fbn1ff1j-8000.inc1.devtunnels.ms/api/v1/${endpoint}`,
    method,
    data,
    headers: {
      "Content-Type": isMultipart ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  })};
